const usersTypes = {
  SET_USER_SELECTED: 'SET_USER_SELECTED',
  SET_ROLES_LIST: 'SET_ROLES_LIST',
  SET_PERMISSIONS_LIST: 'SET_PERMISSIONS_LIST',
  MODAL_CREATE_USER: 'MODAL_CREATE_USER',
  MODAL_VIEW_USER: 'MODAL_VIEW_USER',
  MODAL_UPDATE_USER: 'MODAL_UPDATE_USER',
  FETCH_ALL_USERS: 'FETCH_ALL_USERS',
  FETCH_ONE_USER: 'FETCH_ONE_USER',
  FETCH_ROLES_PER_TYPE_USER: 'FETCH_ROLES_PER_TYPE_USER',
  FETCH_PERMISSIONS_BY_ROLE: 'FETCH_PERMISSIONS_BY_ROLE',
  USER_CREATED: 'USER_CREATED',
  USER_DELETED: 'USER_DELETED',
  USER_UPDATED: 'USER_UPDATED',
  SET_USERS_SECTION_SELECTED: 'SET_USERS_SECTION_SELECTED',
  SET_ALL_ROLES: 'SET_ALL_ROLES',
};

export default usersTypes;
