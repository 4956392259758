import { Suspense } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { ConnectedRouter } from 'connected-react-router';

import '@fontsource/poppins';
import '@fontsource/poppins/700.css'; // Weight 500.

import ScrollToTop from './components/Shared/ScrollToTop';
import { history } from './state/store';
import Loading from './components/UI/Loading';
// import Whatsapp from './components/Navigation/Chat';
import RenderRoutes from './routes/RenderRoutes';
import theme from './utils/themeProvider';

function App() {
  return (
    <Suspense
      fallback={
        <Loading
          loading
          center
          centerVertically
          size={80}
          height={window.innerHeight}
          width={window.innerWidth}
          showLogo
        />
      }
    >
      <ChakraProvider theme={theme}>
        <ConnectedRouter history={history}>
          <ScrollToTop />

          <RenderRoutes />
        </ConnectedRouter>
        {/* <Whatsapp /> */}
      </ChakraProvider>
    </Suspense>
  );
}

export default App;

